.titulo{
    text-align: center;
    align-items: center;
    margin: 0;
    margin-top: 50px;
    font-weight: bold;
    font-family: 'Times New Roman', Times, serif;
    font-size: 12;
}
p{
    text-align: justify;
    font-family: arial, 'Times New Roman', Times, serif;
    font-size: 10;
    margin-top: 1cm;
}
.hoja{
    margin: 1cm;
}
.center{text-align: center;
    align-items: center;

}