@import './assets/flags/flags.css';

.floatlabel-demo {
    .p-field {
        margin-top: 2rem;
        margin-bottom: 0;
    }
}

.input-demo {
    .p-multiselect {
        min-width: 15rem;
    }

    .multiselect-custom {
        .country-item {
            display: flex;
            align-items: center;

            span.flag {
                width: 18px;
                height: 12px;
                margin-right: .5rem;
                margin-left: .5rem;
            }
        }

        .country-item-value {
            border-radius: 3px;
            display: inline-flex;
            vertical-align: top;
            margin-right: .5rem;
            padding-right: .5rem;
            background-color: #2196F3;
            color: #ffffff;
        }
    }
}

.list-demo {
    .product-name {
        font-size: 1.5rem;
        font-weight: 700;
    }

    .product-description {
        margin: 0 0 1rem 0;
    }

    .product-category-icon {
        vertical-align: middle;
        margin-right: .5rem;
    }

    .product-category {
        font-weight: 600;
        vertical-align: middle;
    }

    .product-list-item {
        display: flex;
        align-items: center;
        padding: 1rem;
        width: 100%;

        img {
            width: 150px;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
            margin-right: 2rem;
        }

        .product-list-detail {
            flex: 1 1 0;
            -ms-flex: 1 1 0px;
        }

        .p-rating {
            margin: 0 0 .5rem 0;
        }

        .product-price {
            font-size: 1.5rem;
            font-weight: 600;
            margin-bottom: .5rem;
            align-self: flex-end;
        }

        .product-list-action {
            display: flex;
            flex-direction: column;
        }

        .p-button {
            margin-bottom: .5rem;
        }
    }

    .product-badge {
        border-radius: 2px;
        padding: .25em .5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: .3px;

        &.status-instock {
            background: #C8E6C9;
            color: #256029;
        }

        &.status-outofstock {
            background: #FFCDD2;
            color: #C63737;
        }

        &.status-lowstock {
            background: #FEEDAF;
            color: #8A5340;
        }
    }

    .product-grid-item {
        margin: .5em;
        border: 1px solid var(--surface-d);

        .product-grid-item-top,
        .product-grid-item-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        img {
            width: 75%;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
            margin: 2rem 0;
        }

        .product-grid-item-content {
            text-align: center;
        }

        .product-price {
            font-size: 1.5rem;
            font-weight: 600;
        }
    }

    @media screen and (max-width: 576px) {
        .product-list-item {
            flex-direction: column;

            img {
                width: 75%;
                margin: 2rem 0;
            }

            .product-list-detail {
                text-align: center;
            }

            .product-price {
                align-self: center;
            }

            .product-list-action {
                display: flex;
                flex-direction: column;
            }

            .product-list-action {
                margin-top: 2rem;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
            }
        }
    }
}

.media-demo {
    .product-item {
        .product-item-content {
            border: 1px solid var(--surface-d);
            border-radius: 3px;
            margin: 0.3rem;
            text-align: center;
            padding: 2rem 0;
        }

        .product-image {
            width: 50%;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        }
    }
}

.menu-demo {
    .stepsdemo-content {
        padding: 1em 0;

        p {
            font-weight: 400;
            display: inline-block;
            vertical-align: middle;
            font-size: 18px;
            margin: 0;
        }

        i {
            vertical-align: middle;
            font-size: 1.5em;
            margin: 0;
        }
    }

    .contextmenu-image {
        width: 100%;
    }
}

.messages-demo {
    .p-field>label {
        width: 125px;
    }

    .p-inputtext {
        margin-right: .5rem;
    }
}

.misc-demo {
    .badges {

        .p-badge,
        .p-tag {
            margin-right: .5rem;
        }
    }

    .p-button {
        margin-right: .5rem;
    }

    .p-overlay-badge {
        margin-right: 2rem;
    }

    @keyframes p-progress-spinner-color {

        100%,
        0% {
            stroke: #2196F3;
        }

        40% {
            stroke: #ec407a;
        }

        66% {
            stroke: #66bb6a;
        }

        80%,
        90% {
            stroke: #ffa726;
        }
    }
}

.overlay-demo {
    p {
        line-height: 1.5;
        margin: 0;
    }

    .product-image {
        width: 50px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }
}

.panel-demo {
    .p-toolbar {
        flex-wrap: wrap;
        overflow: auto;
    }

    p {
        line-height: 1.5;
        margin: 0;
    }

    .p-card .p-card-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0;
    }
}

.table-demo {
    // tr td:last-child {
    //     background: yellow;
    // }


    tr:last-child // td:nth-child(2)

        {
        font-weight: bold;
        background: rgb(212, 212, 210);
    }

    // tr,
    // tr:last-child(tr) {
    //     background: yellow;
    // }

    .p-progressbar {
        height: .5rem;
        background-color: #D8DADC;

        .p-progressbar-value {
            background-color: #607D8B;
        }
    }

    .p-datatable .p-column-filter {
        display: none;
    }

    .table-header {
        display: flex;
        justify-content: space-between;

        @media(max-width: 768px) {
            flex-direction: column;
        }
    }

    .p-datatable.p-datatable-customers {

        .p-datatable-tbody {
            >tr {
                border-bottom: 1px solid var(--surface-d);
            }
        }

        .p-datatable-header {
            padding: 1rem;
            text-align: left;
            font-size: 1.5rem;
        }

        .p-paginator {
            padding: 1rem;
        }

        .p-datatable-thead>tr>th {
            text-align: left;
        }

        .p-datatable-tbody>tr>td {
            cursor: auto;
        }

        .p-dropdown-label:not(.p-placeholder) {
            text-transform: uppercase;
        }

    }

    /* Responsive */
    .p-datatable-customers .p-datatable-tbody>tr>td .p-column-title {
        display: none;
    }

    .customer-badge {
        border-radius: 2px;
        padding: .25em .5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: .3px;

        &.status-qualified {
            background: #C8E6C9;
            color: #256029;
        }

        &.status-unqualified {
            background: #FFCDD2;
            color: #C63737;
        }

        &.status-negotiation {
            background: #FEEDAF;
            color: #8A5340;
        }

        &.status-new {
            background: #B3E5FC;
            color: #23547B;
        }

        &.status-renewal {
            background: #ECCFFF;
            color: #694382;
        }

        &.status-proposal {
            background: #FFD8B2;
            color: #805B36;
        }
    }

    .p-progressbar-value.ui-widget-header {
        background: #607d8b;
    }

    @media (max-width: 640px) {
        .p-progressbar {
            margin-top: .5rem;
        }
    }

    .product-image {
        width: 100px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
    }

    .orders-subtable {
        padding: 1rem;
    }

    .product-badge {
        border-radius: 2px;
        padding: .25em .5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: .3px;

        &.status-instock {
            background: #C8E6C9;
            color: #256029;
        }

        &.status-outofstock {
            background: #FFCDD2;
            color: #C63737;
        }

        &.status-lowstock {
            background: #FEEDAF;
            color: #8A5340;
        }
    }

    .order-badge {
        border-radius: 2px;
        padding: .25em .5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: .3px;

        &.order-delivered {
            background: #C8E6C9;
            color: #256029;
        }

        &.order-cancelled {
            background: #FFCDD2;
            color: #C63737;
        }

        &.order-pending {
            background: #FEEDAF;
            color: #8A5340;
        }

        &.order-returned {
            background: #ECCFFF;
            color: #694382;
        }
    }

    @media screen and (max-width: 960px) {
        .p-datatable {
            &.p-datatable-customers {

                .p-datatable-thead>tr>th,
                .p-datatable-tfoot>tr>td {
                    display: none !important;
                }

                .p-datatable-tbody>tr {
                    >td {
                        text-align: left;
                        display: block;
                        border: 0 none !important;
                        width: 100% !important;
                        float: left;
                        clear: left;
                        border: 0 none;

                        .p-column-title {
                            padding: .4rem;
                            min-width: 30%;
                            display: inline-block;
                            margin: -.4rem 1rem -.4rem -.4rem;
                            font-weight: bold;
                        }

                        .p-progressbar {
                            margin-top: .5rem;
                        }
                    }
                }
            }
        }
    }
}

.crud-demo {
    .table-header {
        flex-direction: column;

        @media screen and (min-width: 768px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row !important;
        }
    }

    .product-image {
        width: 100px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }

    .p-toolbar {
        display: flex;
        flex-wrap: wrap;
    }

    .p-dialog .product-image {
        width: 150px;
        margin: 0 auto 2rem auto;
        display: block;
    }

    .confirmation-content {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .product-badge {
        border-radius: 2px;
        padding: .25em .5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: .3px;

        &.status-instock {
            background: #C8E6C9;
            color: #256029;
        }

        &.status-outofstock {
            background: #FFCDD2;
            color: #C63737;
        }

        &.status-lowstock {
            background: #FEEDAF;
            color: #8A5340;
        }
    }

    /* Responsive */
    .datatable-responsive .p-datatable-tbody>tr>td .p-column-title {
        display: none;
    }

    @media screen and (max-width: 960px) {
        .p-datatable {
            &.datatable-responsive {

                .p-datatable-thead>tr>th,
                .p-datatable-tfoot>tr>td {
                    display: none !important;
                }

                .p-datatable-tbody>tr {
                    border-bottom: 1px solid var(--surface-d);

                    >td {
                        text-align: left;
                        display: flex;
                        align-items: center;
                        border: 0 none !important;
                        width: 100% !important;
                        float: left;
                        clear: left;
                        border: 0 none;

                        .p-column-title {
                            padding: .4rem;
                            min-width: 30%;
                            display: inline-block;
                            margin: -.4rem 1rem -.4rem -.4rem;
                            font-weight: bold;
                        }

                        .p-progressbar {
                            margin-top: .5rem;
                        }

                        .actions {
                            display: flex;
                            flex-grow: 1;
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }
}

.elevation-demo {
    .box {
        min-height: 100px;
        min-width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        margin: 2rem;
        border-radius: 4px;
    }
}

.flexbox-demo {

    .p-d-flex>div,
    .box {
        background-color: var(--surface-e);
        text-align: center;
        padding: 1rem;
        border-radius: 4px;
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    }

    .p-d-flex>div {
        width: 8rem;
    }

    i:not([class~="pi"]) {
        background-color: #ffffff;
        color: #2196f3;
        font-family: Monaco, courier, monospace;
        font-style: normal;
        font-size: 12px;
        padding: 2px 4px;
        letter-spacing: .5px;
        border-radius: 3px;
        font-weight: 600;
        margin: 0 2px;
    }
}

.flexgrid-demo {
    border-radius: 2px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    padding: 20px;
    background-color: #f4f4f7;

    .box {
        background-color: var(--surface-e);
        text-align: center;
        padding: 1.25rem;
        font-size: 1.5rem;
        border-radius: 4px;
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    }

    .box-stretched {
        height: 100%;
    }

    .vertical-container {
        margin: 0;
        height: 200px;
        background: var(--surface-d);
        border-radius: 4px;
    }

    .nested-grid .p-col-4 {
        padding-bottom: 1rem;
    }

    .dynamic-box-enter-active,
    .dynamic-box-leave-active {
        transition: all .5s;
    }

    .dynamic-box-enter-from,
    .dynamic-box-leave-to {
        opacity: 0;
    }

    .dynamic-box-enter-from,
    .dynamic-box-leave-to {
        transform: translateX(30px);
    }

    p {
        margin: 0;
    }
}

.icons-demo {
    .icon-filter {
        width: 100%;
        padding: 1rem;
        margin: 1rem 0 1.5rem 0;
    }

    .icons-list {
        text-align: center;
        color: var(--text-color);

        .p-md-2 {
            padding: 1em;
        }
    }

    .icons-list i {
        font-size: 1.5rem;
        margin-bottom: .5rem;
        color: var(--text-color-secondary);
    }
}

.spacing-demo {
    .demo-container {
        border: 1px solid var(--surface-d);
    }
}

.text-demo {
    .demo-container {
        border: 2px solid #dee2e6;
    }
}

pre[class*="language-"] {

    &:before,
    &:after {
        display: none !important;
    }

    code {
        border-left: 10px solid var(--surface-d) !important;
        box-shadow: none !important;
        background: var(--surface-b) !important;
        margin: 1em 0;
        color: var(--text-color);
        font-size: 14px;

        .token {

            &.tag,
            &.keyword {
                color: #2196F3 !important;
            }

            &.attr-name,
            &.attr-string {
                color: #2196F3 !important;
            }

            &.attr-value {
                color: #4CAF50 !important;
            }

            &.punctuation {
                color: var(--text-color);
            }

            &.operator,
            &.string,
            &.entity,
            &.url,
            &.variable {
                background: transparent;
            }
        }
    }
}

.timeline-demo {
    .custom-marker {
        display: flex;
        width: 2rem;
        height: 2rem;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        border-radius: 50%;
        z-index: 1;
    }

    .p-timeline-event-content,
    .p-timeline-event-opposite {
        line-height: 1;
    }

    @media screen and (max-width: 960px) {
        .customized-timeline {
            .p-timeline-event:nth-child(even) {
                flex-direction: row !important;

                .p-timeline-event-content {
                    text-align: left !important;
                }
            }

            .p-timeline-event-opposite {
                flex: 0;
            }

            .p-card {
                margin-top: 1rem;
            }
        }
    }
}

@media screen and (max-width: 960px) {
    .fc-toolbar {
        &.fc-header-toolbar {
            flex-wrap: wrap;
        }
    }
}